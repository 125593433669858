<template>
  <div class="top-sheet bg-white shadow-2">
    <div class="row">
      <div
        v-if="title"
        class="title col-sm-12"
      >
        <div class="text-h4 text-weight-light">
          {{ title }}
        </div>
      </div>
    </div>
    <slot name="tools" />
    <resource-table-head
      v-if="columns"
      :full-width="fullWidth"
      :columns="columns"
      class="table-head"
    />
  </div>
</template>

<script>
import resourceTableHead from '../MResourceTableHead'
export default {
  components: {
    resourceTableHead
  },
  props: {
    title: String,
    columns: Array,
    fullWidth: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.top-sheet
  padding 0 1rem
  position relative
  z-index 100

  @media (min-width: 768px)
    padding 0 2rem

.title
  margin-top 15px
    @media (min-width: 768px)
      margin-bottom 15px
h4
  text-transform capitalize
  font-weight 300

.table-head
  display none

@media (min-width: 768px) {
  .table-head {
    display: flex;
  }

  .title {
    margin-top 2rem
    margin-bottom 0
  }
}
</style>
